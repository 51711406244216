import { useSiteStore } from '../stores/site.store';

export default defineNuxtPlugin({
  dependsOn: ['init-client'],
  parallel: true,
  setup() {
    const config = useSiteStore();

    const lookupFeature = (key: string): boolean => {
      return config.getFeatureFlags.get(key);
    };

    window.$enabled = lookupFeature;
    window.$featureFlagKeys = () => Array.from(config.getFeatureFlags.keys());
    return {
      provide: {
        enabled: lookupFeature,
      },
    };
  },
});
