<script setup lang="ts">
interface IThemedImage {
  imageClass?: string;
  altText?: string;
  containerStyle?: string;
  light: string;
  dark: string;
}

const props = withDefaults(defineProps<IThemedImage>(), {
  altText: '',
  imageClass: '',
});

const image: globalThis.Ref<string> = ref(props.dark);
watch(
  () => useColorMode(),
  (value) => {
    nextTick(() => {
      image.value = value.preference === 'dark' ? props.dark : props.light;
    });
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <img :key="image" :class="props.imageClass" :src="image" :alt="altText" />
</template>

<style scoped></style>
