<template>
  <div
    class="maintenance absolute h-full w-full flex justify-center items-center z-1 text-base-priority"
  >
    <div
      class="flex items-center bg-primary-layer p-1 rounded-md text-xs md:text-normal"
    >
      <div class="gold-dot flex-center relative mr-1 text-xs">
        {{ '' }}
      </div>
      <strong>
        {{ $t('in-maintenance') }}
      </strong>
    </div>
  </div>
</template>
<style scoped>
.gold-dot {
  width: 12px;
  height: 12px;
}
.gold-dot::before {
  content: ' ';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #d87700 0%, #ffc018);
  @apply animate-pulse;
}

.gold-dot::after {
  content: ' ';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  opacity: 0.25;
  background-color: #ffc018;
}
.dark .maintenance {
  background-color: #1d2129c9;
}
.maintenance {
  background-color: #ffffffc9;
}
</style>
