import { useStorageService } from '~/composables/services/useStorageService';
import { useMarketingService } from '~/composables/services/useMarketingService';

export default defineNuxtPlugin({
  name: 'banner-tag',
  parallel: true,
  hooks: {
    'app:mounted': async () => {
      const { $marketingService, $storageService } = useServices();
      const route = useRoute();
      const originalQueryString = $storageService.getCookie({
        key: 'OriginalQueryString',
      });

      const SetQueryStringParams = () => {
        const websiteUrl = window.location.href;

        const t = websiteUrl.split('?');
        t.length === 2
          ? $storageService.setCookie({
              key: 'OriginalQueryString',
              data: t[1],
              expiry: 7,
            })
          : $storageService.setCookie({
              key: 'OriginalQueryString',
              data: '/',
              expiry: 7,
            });
      };

      const masterToken = $storageService.getCookie({ key: 'MT' });
      const sessionToken = $storageService.getCookie({ key: 'ST' });

      const referrer =
        document.referrer.replace(/\?.*/gm, '') || window.location.origin;

      setInterval(() => {
        const currentBtag = $storageService.getCookie({ key: 'BTAGCOOKIE' });

        if (!currentBtag && !!route.query.btag) {
          $storageService.setCookie({
            key: 'BTAGCOOKIE',
            data: String(route.query.btag),
            expiry: 7,
          });
          $storageService.setCookie({
            key: 'REFERRERBTAGCOOKIE',
            data: String(route.query.btag),
          });
        } else if (!!currentBtag && !!route.query.btag) {
          $storageService.setCookie({
            key: 'REFERRERBTAGCOOKIE',
            data: String(route.query.btag),
          });
        }
      }, 5000);

      try {
        if (!originalQueryString) SetQueryStringParams();
        // await getOriginToken();
        if (!sessionToken || !masterToken)
          await $marketingService.fetchOriginTokens();
        await $marketingService.fetchBannerTags({ referrer });
      } catch (e) {
        console.error(e);
      }
    },
  },
});
