<script setup lang="ts">
interface ILogo {
  imageClass?: string;
  containerStyle?: string;
  theme?: 'light' | 'dark' | 'both';
}

const props = withDefaults(defineProps<ILogo>(), {
  imageClass: '',
  containerStyle: '',
  theme: 'both',
});

const imagesPath: Record<string, string> = {
  zadark: useImagePath(
    '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/JPCLogo_Glow.png'
  ),
  zalight: useImagePath(
    '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/jpc_logo_light.svg'
  ),
  roadark: useImagePath(
    '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/jpccasino_logo_dark.svg'
  ),
  roalight: useImagePath(
    '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/jpccasino_logo_light.svg'
  ),
};
const site = useSiteStore();
const image = ref<string>('');

const determineImage = (value) => {
  const region: string = site.logo;
  const regionMode = `${region}${
    props.theme === 'both' ? value.preference : props.theme
  }`;
  image.value = imagesPath[regionMode];
};

watch(
  () => useColorMode(),
  (value) => {
    nextTick(() => {
      determineImage(value);
    });
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <img
    fetchpriority="high"
    :key="image"
    :class="props.imageClass"
    :src="image"
    alt="Jackpotcity"
  />
</template>

<style scoped></style>
