<script setup lang="ts">
const Icon = useIcon();
import { LockIcon } from 'assets/data/icons';

interface IBindings {
  'fill-rule': string;
  'clip-rule': string;
}

const bindings: IBindings = {
  'fill-rule': 'evenodd',
  'clip-rule': 'evenodd',
};
</script>
<template>
  <Icon
    :path="LockIcon"
    :size="32"
    :filled="true"
    class="w-6"
    :bindings="bindings"
  />
</template>
