import {
  LazyAccountHistoryBonusWallet,
  LazyAccountHistoryDetailedTransaction,
  LazyAccountHistoryTransactionSummary,
  LazyAccountLogout,
  LazyAccountSettings,
  LazyAccountSettingsDocumentVerification,
  LazyAccountSettingsPersonalDetails,
  LazyAccountSettingsResponsibleGaming,
  LazyAccountSettingsUpdatePassword,
  LazyAccountTellerDeposit,
  LazyAccountTellerWithdrawal,
} from '#components';
import { useSiteStore } from '~/stores/site.store';

interface IUserAccountOptions {
  active: boolean;
  id: string;
  key: string;
  featureFlag?: string | null;
  runtimeOverride?: null | string;
  icon: any;
  component: any;
  includeInHamburger: boolean;
}

let accountOptions: IUserAccountOptions[] = [
  {
    active: true,
    id: 'deposit',
    key: 'deposit',
    icon: null,
    component: LazyAccountTellerDeposit,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'withdrawal',
    key: 'withdrawal',
    icon: null,
    component: LazyAccountTellerWithdrawal,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'transaction-summary',
    key: 'transaction-summary',
    featureFlag: 'myaccount.transactionhistory',
    icon: null,
    component: LazyAccountHistoryTransactionSummary,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'transaction-summary-detail',
    key: 'transaction-summary-detail',
    icon: null,
    component: LazyAccountHistoryDetailedTransaction,
    includeInHamburger: false,
  },
  {
    active: true,
    id: 'bonus-wallet',
    key: 'bonus-wallet',
    featureFlag: 'myaccount.bonusbalance',
    icon: null,
    component: LazyAccountHistoryBonusWallet,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'personal-details',
    key: 'personal-details',
    featureFlag: 'myaccount.personaldetails',
    icon: null,
    component: LazyAccountSettingsPersonalDetails,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'password-management',
    key: 'password-management',
    featureFlag: 'myaccount.updatepassword',
    icon: null,
    component: LazyAccountSettingsUpdatePassword,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'account-settings',
    key: 'account-settings',
    featureFlag: 'myaccount.managesubscriptions',
    icon: null,
    component: LazyAccountSettings,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'responsible-gaming',
    key: 'responsible-gaming',
    icon: null,
    featureFlag: 'myaccount.responsiblegaming',
    component: LazyAccountSettingsResponsibleGaming,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'document-verification',
    key: 'document-verification',
    featureFlag: 'website.documentverification',
    icon: null,
    component: LazyAccountSettingsDocumentVerification,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'logout',
    key: 'logout',
    icon: null,
    component: LazyAccountLogout,
    includeInHamburger: true,
  },
];

export const useAccountOptions = () => {
  const runtimeConfig = useRuntimeConfig();
  const siteStore = useSiteStore();
  const featureFlags = siteStore.getFeatureFlags;
  return accountOptions.map((accountOption) => {
    const ff = accountOption?.featureFlag;
    if (!!ff)
      return {
        ...accountOption,
        active: !!accountOption?.runtimeOverride
          ? runtimeConfig?.public[accountOption?.runtimeOverride]
          : featureFlags.get(ff),
      };
    return accountOption;
  });
};

export const useUpsertAccountOptions = (request: IUserAccountOptions[]) => {
  accountOptions = request;
};
