<script setup lang="ts">
import { ExclamationIcon } from '~/components/design-system/icons';
import Button from '~/components/design-system/button.vue';
import { ChevronDownIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';

interface IUserBalanceProps {
  amount?: number;
  label: string;
  error?: boolean;
  hideBalance?: boolean;
  loading?: boolean;
  toggleBalanceModal?: boolean;
  toggleRefreshBalances?: boolean;
  isAccountModal?: boolean;
  backgroundColor?:
    | 'bg-primary-layer'
    | 'bg-primary-layer-alternative'
    | 'bg-base';
  singleLine?: boolean;
  truncateBalance?: boolean;
}
const siteStore = useSiteStore();
const { $walletService } = useServices();
const refreshBalances = async () => {
  await $walletService.fetchBalances();
};

const props = withDefaults(defineProps<IUserBalanceProps>(), {
  amount: 0,
  error: false,
  hideBalance: false,
  loading: false,
  toggleBalanceModal: false,
  refreshBalances: false,
  isAccountModal: false,
  backgroundColor: 'bg-primary-layer-alternative',
  singleLine: false,
  truncateBalance: false,
});
</script>

<template>
  <div
    class="rounded-xl text-xs flex items-center justify-between h-10 text-base-priority px-1.5 relative overflow-hidden"
    :class="[
      { 'min-w-[40vw] sm:min-w-[20vw]': props.isAccountModal },
      backgroundColor,
    ]"
  >
    <div
      v-if="loading"
      class="absolute w-full h-full flex items-center justify-center backdrop-blur-sm top-0 left-0"
    >
      <svg
        class="animate-spin text-base w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
    <div
      :class="[
        'p-1',
        'font-bold',
        { 'flex justify-between w-full': singleLine },
      ]"
    >
      <div class="truncate capitalize">
        {{ props.label }}
      </div>
      <div v-if="error" class="flex justify-center">
        <ExclamationIcon
          class="text-primary-gold-500 w-4"
          tooltip="could-not-retrieve-balances"
        />
      </div>
      <div 
        v-else-if="!hideBalance"
        v-html="truncateBalance ? useFormatCurrency(amount, true) : useFormatCurrency(amount)"
      />
      <div v-else-if="hideBalance">●●●●●</div>
    </div>
    <slot name="suffix-slot" />
    <Button
      v-if="toggleRefreshBalances"
      type="transparent"
      padding="sm"
      icon
      @click="refreshBalances()"
    >
      <ArrowPathIcon class="w-5" />
    </Button>
    <Button
      v-if="toggleBalanceModal"
      type="transparent"
      padding="sm"
      icon
      @click="siteStore.activateModal('balances')"
    >
      <ChevronDownIcon class="w-5" />
    </Button>
  </div>
</template>

<style scoped lang="scss"></style>
