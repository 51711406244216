<script setup lang="ts">
const { $imageObserver } = useNuxtApp();
interface ILazyImageProps {
  src: string;
  class?: string | any[];
  style?: string | Record<string, string>;
  alt?: string;
  placeholder?: string;
}
const props = withDefaults(defineProps<ILazyImageProps>(), {
  placeholder: '',
  class: '',
  style: '',
  alt: '',
});

const image = ref();

onMounted(() => $imageObserver(image.value));
</script>

<template>
  <img
    ref="image"
    class="text-center user-select-none"
    :class="props.class"
    :src="props.placeholder"
    :lazy-src="props.src"
    :style="props.style"
    :alt="props.alt || props.src"
  />
</template>

<style scoped></style>
