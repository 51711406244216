<script setup lang="ts">
import { SunIcon, MoonIcon } from '@heroicons/vue/24/solid';
import Button from '../../components/design-system/button.vue';
const colorMode = useColorMode();

const toggleDark = () => {
  colorMode.preference === 'dark'
    ? (colorMode.preference = 'light')
    : (colorMode.preference = 'dark');
};
</script>
<template>
  <Button type="tertiary" @click="toggleDark()">
    <component :is="colorMode.preference === 'dark' ? SunIcon : MoonIcon" />
  </Button>
</template>
