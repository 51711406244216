<script setup lang="ts">
  import { ChatIcon } from 'assets/data/icons';
  const colorMode = useColorMode();
  const fillValue = computed(() => {
    return colorMode.preference === 'dark' ? 'url(#light-gradient)' : 'url(#dark-gradient)';
  });
</script>

<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="32" 
    height="32" 
    viewBox="0 0 32 32" 
    :class="fillValue"
  >
    <path 
      :d='ChatIcon'
      :fill="fillValue"
    />
    <defs>
      <linearGradient id="dark-gradient" x1="16.0003" y1="2.6665" x2="16.0003" y2="27.4744" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6A6B7C"/>
        <stop offset="1" stop-color="#2F2F37"/>
      </linearGradient>
      <linearGradient id="light-gradient" x1="16.0003" y1="2.66663" x2="16.0003" y2="27.4745" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F8F8F9"/>
        <stop offset="1" stop-color="#C5C6CE"/>
      </linearGradient>
    </defs>
  </svg>
</template>
<style scoped></style>