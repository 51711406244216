// eslint-disable-next-line
import { Analytics } from '@analytics/core';
import googleTagManager from '@analytics/google-tag-manager';
import AnalyticsEnums from './analytics/enums';

type PageData = {
  title?: string;
  url?: string;
  path?: string;
  search?: string;
  width?: string;
  height?: string;
};

/* eslint-disable */
interface TrackingVariables {
  name: string;
  link_url?: string;
  link_action?: string;
  channel?: string;
  banner_tag?: string;
  player_id?: string;
  master_token?: string;
  session_token?: string;
}
/* eslint-enable */
export let trackingMethods;
export default defineNuxtPlugin(() => {
  const analyticsClient = Analytics({
    app: 'jackpot-city',
    version: '1',
    plugins: [googleTagManager({ containerId: 'GTM-59D9F64' })],
    debug: true,
  });

  trackingMethods = {
    loadPage: (params: PageData) => analyticsClient?.page(params),
    trackEvent: (eventName: string, params?: unknown) => {
      return analyticsClient?.track(eventName, params);
    },
    identifyUser: (userId: string, userInfo?: unknown) =>
      analyticsClient?.identify(userId, userInfo),

    // ===================== Account
    // Login Tracking Methods
    login: (msisdn: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.LoginEvent.LOGIN_INIT, {
        msisdn,
      }),
    loginComplete: (msisdn: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.LoginEvent.LOGIN_SUCCESS, {
        msisdn,
      }),
    loginFailure: (msisdn: string, reason: any) =>
      trackingMethods.trackEvent(AnalyticsEnums.LoginEvent.LOGIN_FAILURE, {
        msisdn,
        reason,
      }),

    // Registration Tracking Methods
    register: (msisdn: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.RegistrationEvent.REG_INIT, {
        msisdn,
      }),
    registerLoaded: () => {
      return trackingMethods.trackEvent(
        AnalyticsEnums.RegistrationEvent.REG_PAGE_LOADED
      );
    },
    registerPersonalComplete: (msisdn: string) =>
      trackingMethods.trackEvent(
        AnalyticsEnums.RegistrationEvent.PERSONAL_DETAILS_COMPLETED,
        {
          msisdn,
        }
      ),
    registerComplete: (msisdn: string) =>
      trackingMethods.trackEvent(
        AnalyticsEnums.RegistrationEvent.REG_COMPLETED,
        {
          msisdn,
        }
      ),
    registerFailure: (msisdn: string, reason: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.RegistrationEvent.REG_FAILURE, {
        msisdn,
        reason,
      }),
    registerAbandoned: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.RegistrationEvent.REG_ABANDONED
      ),

    // Account Page tracking methods
    myAccountTeller: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Account.MY_ACCOUNT_TELLER),
    myAccountHistory: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Account.MY_ACCOUNT_HISTORY),
    myAccountSettings: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Account.MY_ACCOUNT_SETTINGS),
    myAccountLogout: () =>
      trackingMethods.trackEvent(AnalyticsEnums.Account.MY_ACCOUNT_LOGOUT),
    myAccountDetailsUpdate: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_DETAILS_UPDATE_ACTION
      ),
    myAccountPasswordUpdate: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_PASSWORD_UPDATE_ACTION
      ),
    myAccountSettingsUpdate: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.Account.MY_ACCOUNT_SETTINGS_UPDATE_ACTION
      ),

    // ===================== Navigation
    // Search and Filter Methods
    filter: (category: any, provider: any, theme: any) =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.FILTER_GAMES, {
        category,
        provider,
        theme,
      }),
    toggleSearch: () =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.TOGGLE_SEARCH),
    searchQuery: (searchQuery: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.SEARCH, {
        searchQuery,
      }),
    searchQueryEmpty: () =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.SEARCH_EMPTY),
    searchFailure: (searchFailure: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.SearchEvent.SEARCH_FAILURE, {
        searchFailure,
      }),
    searchResultSuccess: (searchResult: string) =>
      trackingMethods.trackEvent(
        AnalyticsEnums.SearchEvent.SEARCH_RESULT_SUCCESS,
        { searchResult }
      ),

    // Route Changes
    routeUnavailable: () => trackingMethods.loadPage({ title: '404' }),
    routeChange: (to: string, from: string) =>
      trackingMethods.trackEvent('RouteChange', { to, from }),
    headerClicked: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.NavigationEvent.HEADER_LINK_CLICK
      ),
    footerClicked: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.NavigationEvent.FOOTER_LINK_CLICK
      ),

    // User Action events
    toggleHamburgerMenu: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.NavigationEvent.OPEN_HAMBURGER_MENU
      ),
    liveChatInit: () =>
      trackingMethods.trackEvent(AnalyticsEnums.LiveChatEvent.LIVE_CHAT_INIT),
    liveChatClosed: () =>
      trackingMethods.trackEvent(AnalyticsEnums.LiveChatEvent.LIVE_CHAT_CLOSED),
    bannerClicked: () =>
      trackingMethods.trackEvent(AnalyticsEnums.BannerEvent.BANNER_CTA_CLICK),
    bannerInfoClicked: () =>
      trackingMethods.trackEvent(
        AnalyticsEnums.BannerEvent.BANNER_MORE_INFO_CTA_CLICK
      ),

    // Games Events
    launchReal: (game: string, msisdn: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.LAUNCH_REAL_GAME, {
        game,
        msisdn,
      }),
    launchDemo: () =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.LAUNCH_DEMO_GAME),
    likeGame: (game: string, likes: number) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.LIKE_GAME, {
        game,
        likes,
      }),
    dislikeGame: (game: string, likes: number) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.DISLIKE_GAME, {
        game,
        likes,
      }),
    favouriteGameAdd: (game: string, msisdn: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.FAVOURITE_GAME_ADD, {
        game,
        msisdn,
      }),
    favouriteGameRemove: (game: string, msisdn: string) =>
      trackingMethods.trackEvent(
        AnalyticsEnums.GameEvent.FAVOURITE_GAME_REMOVE,
        {
          game,
          msisdn,
        }
      ),
    socialShare: (game: string, msisdn: string, channel: string) =>
      trackingMethods.trackEvent(AnalyticsEnums.GameEvent.SOCIAL_SHARE, {
        game,
        msisdn,
        channel,
      }),

    // ===================== Site Settings
    toggleLanguage: (locale: string) =>
      trackingMethods.trackEvent('SwitchLanguage', { locale }),
  };
  addRouteMiddleware(
    'route-change',
    (to, from) => {
      trackingMethods?.routeChange(to.path, from.path);
      const action = window?.dtrum?.enterAction(
        `navigate`,
        null,
        null,
        to.fullPath
      );
      window?.dtrum?.leaveAction(action);
      switch (to.name) {
        case '404':
          trackingMethods?.routeUnavailable();
          break;
      }
    },
    { global: true }
  );
});
