<script setup>
import DefaultLayout from '~/components/layout/default.vue';

import FourOFour from '~/components/user-interface/404.vue';
import { useSiteStore } from '~/stores/site.store';
const siteStore = useSiteStore();

const { $localeService, $configService, $sitemapService, $storageService } =
  useServices();
// fetches
const localeCookie = $storageService.getCookie({ key: 'locale' });
await $sitemapService.fetchSitemap(siteStore.regionCode);
await $configService.fetchConfig(siteStore.regionCode);
await $configService.fetchRedirects(siteStore.regionCode);
await $localeService.fetchLocale(localeCookie);

const error = useError();
error && console.error(error.value);
</script>
<template>
  <DefaultLayout>
    <FourOFour />
  </DefaultLayout>
</template>
