<script setup lang="ts">
import LazyImage from '../lazy-image.vue';

interface IExternalIconProps {
  iconName: string;
  themed?: boolean;
}

const props = withDefaults(defineProps<IExternalIconProps>(), {
  themed: true,
});
const imagePath = useImagePath;
</script>
<template>
  <LazyImage
    :class="['mx-auto', { 'invert dark:invert-0': props.themed }]"
    :placeholder="
      imagePath(
        `/medialibraries/content.gmgamingsystems.com/Casino-Category-Icons/v2/all.svg`
      )
    "
    :src="
      imagePath(
        `/medialibraries/content.gmgamingsystems.com/Casino-Category-Icons/v2/${props.iconName}.svg`
      )
    "
  />
</template>
