import {
  AuthLoginV2,
  AuthRegistrationV3,
  CategoryGrid,
  LazyAccountHistoryAssignBonusModal,
  LazyAccountResponsibleGamingAccountLock,
  LazyAccountTellerWithdrawalAlert,
  LazyAuthPostReg01VerifyName,
  LazyAuthPostReg02CardWelcomeOffer,
  LazyAuthPostReg03SecurityQuestion,
  LazyAuthPostReg04PartialCompletion,
  LazyAuthPostReg05FullCompletion,
  LazyUserInterfaceFiltersFilterFields,
  LazyUserInterfaceSearch,
  LazyUserInterfaceShareModal,
  LazyUserPasswordResetEnterOtp,
  LazyUserPasswordResetOtpOption,
  LazyUserPasswordResetSuccess,
  UserInterfaceOfflineNotice,
  LazyAccountResponsibleGamingCoolingOffPeriod,
  LazyAccountResponsibleGamingAccountRestricted,
  LazyAccountResponsibleGamingAccountUnlocked,
  LazyAccountResponsibleGamingSessionLimitExceeded,
  LazyAccountResponsibleGamingWagerLimitExceeded,
  LazyAccountResponsibleGamingLimitsInformation,
  AuthPostReg02CardWelcomeOffer,
} from '#components';
import { ExclamationIcon } from '~/components/design-system/icons/index';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import { uuid } from '~/utilities/uuid';
import type { Modals } from '~/interfaces/dto/general/modals';
import { useGameStore } from '~/stores/games.store';
import UserBalances from '~/components/user/user-balances.vue';

interface IModalContextBindings {
  titleKey: string;
  key: string;
  breakpoints?: Record<string, string>;
  width?: string | number;
  component?: any;
  class?: string;
  closeModalOption?: boolean;
  headerIcon?: any;
  headerIconClass?: string;
  iconTooltip?: string;
}
export default function (currentModal: Modals): IModalContextBindings {
  const game = useGameStore();
  const route = useRoute();
  const bindings: IModalContextBindings = {
    titleKey: '',
    key: uuid(),
  };
  switch (currentModal) {
    case 'search':
      Object.assign(bindings, {
        titleKey: 'search-games',
        component: () => LazyUserInterfaceSearch,
      });
      break;
    case 'passwordResetStepOne':
      Object.assign(bindings, {
        titleKey: 'fogot-password-title',
        component: () => LazyUserPasswordResetOtpOption,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
      });
      break;
    case 'passwordResetStepTwo':
      Object.assign(bindings, {
        titleKey: 'fogot-password-title',
        component: () => LazyUserPasswordResetEnterOtp,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
      });
      break;
    case 'passwordResetStepSuccess':
      Object.assign(bindings, {
        titleKey: 'fogot-password-title',
        component: () => LazyUserPasswordResetSuccess,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: false,
      });
      break;
    case 'registrationVerifyName':
      Object.assign(bindings, {
        titleKey: 'welcome-to-jpc',
        component: () => LazyAuthPostReg01VerifyName,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: false,
      });
      break;
    case 'registrationWelcomeOffer':
      Object.assign(bindings, {
        titleKey: 'welcome-to-jpc',
        component: () => AuthPostReg02CardWelcomeOffer,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: true,
      });
      break;
    case 'registrationSecurityQuestions':
      Object.assign(bindings, {
        titleKey: 'offer-and-acc-security',
        component: () => LazyAuthPostReg03SecurityQuestion,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: false,
      });
      break;
    case 'registrationPartialCompletion':
      Object.assign(bindings, {
        titleKey: 'welcome-to-jpc',
        component: () => LazyAuthPostReg04PartialCompletion,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: false,
      });
      break;
    case 'registrationFullCompletion':
      Object.assign(bindings, {
        titleKey: 'welcome-to-jpc',
        component: () => LazyAuthPostReg05FullCompletion,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
      });
      break;
    case 'register':
      Object.assign(bindings, {
        titleKey: 'jpc-register',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => AuthRegistrationV3,
        closeModalOption: !route?.params.game || route?.query?.freePlay,
      });
      // closeModalOption: !route?.params.game || route?.query?.freePlay,
      break;
    case 'login':
      Object.assign(bindings, {
        titleKey: 'login',
        component: () => AuthLoginV2,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: !route?.params.game || route?.query?.freePlay,
      });
      // closeModalOption: !route?.params.game || route?.query?.freePlay,
      break;
    case 'gameShare':
      Object.assign(bindings, {
        titleKey: 'share',
        component: () => LazyUserInterfaceShareModal,
        width: '320px',
        breakpoints: { '425px': '326px', '360px': '320px' },
      });
      break;
    case 'filters':
      Object.assign(bindings, {
        titleKey: 'filter-by',
        component: () => LazyUserInterfaceFiltersFilterFields,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        class: 'flex flex-row flex-wrap items-center',
        vertical: true,
      });
      break;
    case 'category':
      Object.assign(bindings, {
        titleKey: `vertical.category.${game.returnCurrentCategory}`,
        component: () => CategoryGrid,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        class: 'flex flex-row flex-wrap items-center p-2',
        games: game.returnCategory({ category: game?.returnCurrentCategory }),
        inModal: true,
      });
      break;
    case 'withdrawalAlert':
      Object.assign(bindings, {
        titleKey: 'withdrawal-alert',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => LazyAccountTellerWithdrawalAlert,
        headerIcon: ExclamationIcon,
        headerIconClass: 'text-gold-500 w-6 h-6',
        iconTooltip: 'bonus-wallet-warning-tooltip',
      });
      break;

    // Responsible gaming
    case 'rGExclusionMessage':
      Object.assign(bindings, {
        titleKey: 'cooling-off-period',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => LazyAccountResponsibleGamingCoolingOffPeriod,
      });
      break;
    case 'rGLimitsMessage':
      Object.assign(bindings, {
        titleKey: 'limits',
        width: '420px',
        component: () => LazyAccountResponsibleGamingLimitsInformation,
      });
      break;
    case 'accountLocked':
      Object.assign(bindings, {
        titleKey: 'account-locked',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => LazyAccountResponsibleGamingAccountLock,
        headerIcon: ExclamationIcon,
        headerIconClass: 'text-error-500 w-6 h-6',
        iconTooltip: 'account-lock-tooltip',
      });
      break;
    case 'accountRestricted':
      Object.assign(bindings, {
        titleKey: 'account-restricted',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => LazyAccountResponsibleGamingAccountRestricted,
        headerIcon: ExclamationIcon,
        headerIconClass: 'text-gold-500 w-6 h-6',
        iconTooltip: 'account-restricted-tooltip',
      });
      break;
    case 'accountUnlocked':
      Object.assign(bindings, {
        titleKey: 'account-unlocked',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => LazyAccountResponsibleGamingAccountUnlocked,
        headerIcon: ExclamationIcon,
        headerIconClass: 'text-primary-blue-600 w-6 h-6',
        iconTooltip: 'account-unlocked-tooltip',
      });
      break;
    case 'sessionLimitExceeded':
      Object.assign(bindings, {
        titleKey: 'session-limit-exceeded',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => LazyAccountResponsibleGamingSessionLimitExceeded,
        headerIcon: ExclamationIcon,
        headerIconClass: 'text-gold-500 w-6 h-6',
        iconTooltip: 'session-limit-tooltip',
      });
      break;
    case 'wagerLimitExceeded':
      Object.assign(bindings, {
        titleKey: 'wager-limit-exceeded',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => LazyAccountResponsibleGamingWagerLimitExceeded,
        headerIcon: ExclamationIcon,
        headerIconClass: 'text-gold-500 w-6 h-6',
        iconTooltip: 'wager-limit-tooltip',
      });
      break;

    //
    case 'offline':
      Object.assign(bindings, {
        titleKey: 'offline',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => UserInterfaceOfflineNotice,
        closeModalOption: false,
        inModal: true,
      });
      break;
    case 'transactionAssignBonus':
      Object.assign(bindings, {
        titleKey: 'jpc-assign-bonus-header',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => LazyAccountHistoryAssignBonusModal,
        headerIcon: InformationCircleIcon,
        headerIconClass: 'text-gold-500 w-6 h-6',
      });
      break;
    case 'balances':
      Object.assign(bindings, {
        titleKey: 'account-balances',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: () => UserBalances,
        displayBalanceToggle: true,
        displayRefreshBalance: true,
        displayCashBalance: true,
        displayBonusBalance: true,
        displayDepositButton: true,
        stretch: true,
        class: 'w-full p-4',
      });
      break;
  }

  return bindings;
}
