<script setup lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/24/outline';

import AppleIcon from '../design-system/icons/AppleIcon.vue';
import FacebookIcon from '../design-system/icons/FacebookIcon.vue';
import TwitterIcon from '../design-system/icons/TwitterIcon.vue';
import InstagramIcon from '../design-system/icons/InstagramIcon.vue';
import AppDownload from '~/components/user-interface/app-download.vue';

const HuaweiIcon = useImagePath(
  '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/huawei_icon.svg'
);
const AndroidIcon = useImagePath(
  '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/android_icon.svg'
);

const { $storageService } = useServices();
const devicesList: string[] = ['iPhone', 'Android', 'Huawei'];

interface IAppDownloadProps {
  type?: string;
  background?: string; //background of the link divs
  direction?: 'vertical' | 'horizontal';
}

const props = withDefaults(defineProps<IAppDownloadProps>(), {
  direction: 'horizontal',
});

const deviceName = computed(() => {
  const currentDeviceName: string = devicesList.find(
    (device) => navigator.userAgent.toString().indexOf(device) > -1
  );

  return !!currentDeviceName ? currentDeviceName : 'Desktop';
});

const displayApple = computed(
  () => deviceName.value === 'iPhone' || deviceName.value === 'Desktop'
);
const displayAndroid = computed(
  () =>
    deviceName.value === 'Android' ||
    deviceName.value === 'Desktop' ||
    deviceName.value === 'Huawei'
);

function downloadApp() {
  const btag = $storageService.getCookie({ key: 'BTAGCOOKIE' });
  window.open(
    `https://info.jpc.africa/api/v1/JackpotCityAppDownload/?btag=${
      !!btag ? btag : 'P97425-PR26622-CM89032-TS1984022'
    }`
  );
}
</script>

<template>
  <div
    class="flex gap-2"
    :class="direction === 'vertical' ? 'flex-col' : 'flex-row'"
    @click="downloadApp()"
  >
    <AppDownload v-if="displayApple">
      <template #icon>
        <AppleIcon />
      </template>
      <template #appType>
        {{ $t('app-store') }}
      </template>
    </AppDownload>
    <AppDownload v-if="displayAndroid">
      <template #icon>
        <img :src="AndroidIcon" alt="Jackpotcity Android App" />
      </template>
      <template #appType>
        {{ $t('android') }}
      </template>
    </AppDownload>
    <AppDownload v-if="displayAndroid">
      <template #icon>
        <img :src="HuaweiIcon" alt="Jackpotcity Huawei App" />
      </template>
      <template #appType>
        {{ $t('app-gallery') }}
      </template>
    </AppDownload>
  </div>
</template>
<style lang="scss" scoped></style>
