<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';
import UserCard from '~/components/user-interface/user-card.vue';

const { $t } = useNuxtApp();
const auth = useAuthStore();
const site = useSiteStore();
// const welcomeOffers = reactive([]);
// const chosenOffer = ref('');
const loader = ref(false);
// const chooseLater = $t('choose-later');
const { $marketingService } = useServices();
const isROA = computed(() => site.getRegion !== 'ZA');
onMounted(() => {
  // populate welcome offers
  // welcomeOffers.push('Welcome Offer 1', 'Welcome Offer 2');
  // welcomeOffers.push(chooseLater);
  // chosenOffer.value = chooseLater;
  //Determine if ZA or ROA post reg flow
  if (auth.getWelcomeOffer) {
    $marketingService.setMarketingOptIn({
      vendorAccountID: auth.user_id,
      optinName: 'jpcwelcomeoffer',
      source: 'Web',
      countryCode: site.getRegionCode,
    });
  }

  loader.value = false;
});

function completeReg() {
  loader.value = true;

  /** TODO: uncomment and add meat when API is ready
  make api call for chose welcome offer
  $complianceService.sendWelcomeOffer(chosenOffer.value).then((data) => {
    if (data.isSuccessful) {
      //put welcome offer in state
    } else {
      //error choosing welcome offer
    }
    });
  if (auth.getWelcomeOffer) {
    auth.setWelcomeOffer(
      chosenOffer.value === chooseLater ? '' : chosenOffer.value
    );
  }
   */
  //check if passport or failed verify names
  if (auth.getPartialPostReg) {
    site.activateModal('registrationPartialCompletion'); //partial completion
  } else {
    site.activateModal('registrationFullCompletion');
  }
  loader.value = false;
}

function depositNow() {
  site.deactivateModal();
  auth.toggleAccountModal(true);
  auth.setAccountModalPage('deposit');
}

function depositLater() {
  site.deactivateModal();
}
</script>

<template>
  <div>
    <div class="flex flex-col flex-wrap justify-center items-center mx-3">
      <LazyUserInterfaceGenericLoader v-if="loader" container />
      <UserCard class="mb-3" />

      <!-- <div v-if="auth.getWelcomeOffer">
      <p class="mx-3 mb-2 mt-0">
        <strong>{{ $t('congrats') }}!</strong>
        {{ $t('you-have-qualified-for-welcome-offer') }}
      </p>

      <div
        v-for="offer in welcomeOffers"
        :key="offer"
        class="flex items-center w-full bg-light-200 dark:bg-dark-700 rounded-md mb-2 text-dark-700 dark:text-white mx-3"
      >
        <label :for="offer" class="ml-2 py-1 capitalize">
          {{ offer }}
        </label>
        <RadioButton
          ref="radioButton"
          v-model="chosenOffer"
          :inputId="offer"
          name="dynamic"
          :value="offer"
          class="ml-auto mr-2"
          :pt="{
            input: ({ props }) => ({
              class:
                props.modelValue === offer
                  ? 'blue-gradient border-none'
                  : 'dark:b-dark-500 bg-light-50 b-light-400',
            }),
            icon: ({ props }) => ({
              class: props.modelValue === offer ? 'bg-light-50' : '',
            }),
          }"
        />
      </div>
    </div>-->
    </div>

    <div
      v-if="!isROA"
      class="w-full dark:bg-dark-900 bg-light-200 mt-2 flex justify-content-center"
    >
      <Button class="w-full m-3 justify-center" @click="completeReg()">
        {{ $t('continue') }}
      </Button>
    </div>
    <div
      v-else
      class="w-full dark:bg-dark-900 bg-light-200 mt-2 flex justify-content-center"
    >
      <Button class="w-full m-3 justify-center" @click="depositNow()">
        {{ $t('deposit-now') }}
      </Button>
      <Button
        class="w-full m-3 justify-center"
        type="secondary"
        @click="depositLater()"
      >
        {{ $t('deposit-later') }}
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
p {
  color: #2b303b;
  .dark & {
    color: #fff;
  }
}
</style>

<style lang="scss">
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none;
}
</style>
