<script setup lang="ts">
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline';
import type { severity } from '~/interfaces/dto/general/toast-messages';

interface IErrorProps {
  state?: severity;
}
const props = withDefaults(defineProps<IErrorProps>(), {
  state: 'danger',
});
const determineBorder = computed<string>((): string => {
  switch (props.state) {
    case 'success':
      return 'border-primary-blue-500';
    case 'info':
      return 'border-primary-blue-700';
    case 'danger':
      return 'border-red-500';
    case 'warning':
      return 'border-yellow-500';
    default:
      return 'border-primary-blue-700';
  }
});
const determineIconBg = computed<string>((): string => {
  switch (props.state) {
    case 'success':
      return 'bg-primary-blue-500 text-white';
    case 'info':
      return 'bg-primary-blue-gradient text-white';
    case 'danger':
      return 'bg-red-500 text-white';
    case 'warning':
      return 'bg-yellow-500';
    default:
      return 'bg-primary-blue-gradient';
  }
});
const determineIcon = computed<any>((): any => {
  switch (props.state) {
    case 'success':
      return CheckCircleIcon;
    case 'info':
      return ExclamationCircleIcon;
    case 'danger':
      return XCircleIcon;
    case 'warning':
      return ExclamationCircleIcon;
    default:
      return CheckCircleIcon;
  }
});

const genericResponse = ref(null);

onMounted(() => {
  genericResponse.value.scrollIntoView();
});
</script>
<template>
  <div
    class="mx-auto w-full h-full"
    ref="genericResponse"
    style="max-width: 320px"
  >
    <div
      class="h-full flex justify-content-start items-center text-base text-xs p-2 text-left rounded-md mt-2 border-[1px]"
      :class="[determineBorder]"
    >
      <div
        :class="[determineIconBg]"
        class="w-6 h-6 flex-center rounded-md mr-2"
      >
        <component :is="determineIcon" :key="props.state" class="w-5" />
      </div>
      <slot />
    </div>
  </div>
</template>
