import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';
import { useCheckRestrictions } from '~/composables/useCheckRestrictions';
import {
  defaultLocales,
  environmentVariables,
  subdomainLocales,
} from '~/assets/data/config';
import ToastService from 'primevue/toastservice';
import DatePicker from 'primevue/datepicker';
export default defineNuxtPlugin({
  name: 'init-client',
  parallel: true,
  async setup(nuxt) {
    const { $configService, $storageService } = useServices();
    await $storageService.invalidateStaleIndexDb();

    const route = useRoute();
    const runtimeConfig = useRuntimeConfig();
    const siteStore = useSiteStore();
    const localeStore = useLocaleStore();

    nuxt.vueApp.use(ToastService);
    nuxt.vueApp.component('DatePicker', DatePicker);

    const host = useRequestURL().host.split(':')[0];
    const debugRegionCode = $storageService.getUniversalStorage({
      key: 'debug-region',
    });

    const debugEnvironment = $storageService.getUniversalStorage({
      key: 'debug-environment',
    });

    if (!!debugEnvironment) {
      siteStore.setEnvironment(debugEnvironment);
      Object.assign(
        runtimeConfig?.public,
        environmentVariables[debugEnvironment]
      );
    }

    const testSubdomain = () => !!document.location.host.match(/uat|dev|qa/);
    const localSubdomain = () => !!document.location.host.match(/localhost/);
    const localeSubdomain = () => document.location.host.match(/en|pt|sw/);

    const isTestSubdomain = testSubdomain();
    const isLocalhost = localSubdomain();
    const isLocaleSubdomain = localeSubdomain();
    const localeCookie = $storageService.getCookie({ key: 'locale' });
    const countryCode = isLocalhost
      ? debugRegionCode || runtimeConfig?.public.defaultCountry
      : host?.slice(-2);
    siteStore.setRegion(String(countryCode));

    const presetLocale =
      isTestSubdomain && !!route.query?.locale
        ? String(route.query?.locale)
        : !!isLocaleSubdomain
        ? subdomainLocales[isLocaleSubdomain[0]]
        : !!localeCookie && (isTestSubdomain || isLocalhost)
        ? localeCookie
        : defaultLocales[`J${countryCode}`];
    localeStore.setLocaleCode(presetLocale);

    await $configService.fetchFullConfig(siteStore.regionCode, presetLocale);
  },
  hooks: {
    'page:finish': () => {
      const loading = document.getElementById('global_loading');
      if (loading) {
        loading.style.cssText = 'display:none !important;';
      }
    },
    async 'app:created'(nuxt) {
      const runtimeConfig = useRuntimeConfig();
      const { $storageService } = useServices(nuxt.$nuxt);

      const buildTimestamp = runtimeConfig?.public.buildTimestamp;
      const prevBuildTimeStamp = $storageService.getUniversalStorage({
        key: 'buildTimeStamp',
      });
      if (+prevBuildTimeStamp !== +buildTimestamp) {
        $storageService.invalidateFullCache();
      }
      $storageService.setUniversalStorage({
        key: 'buildTimeStamp',
        data: runtimeConfig?.public.buildTimestamp,
      });

      const route = useRoute();
      const currentBtag = $storageService.getCookie({ key: 'BTAGCOOKIE' });
      if (!currentBtag && !!route.query?.btag) {
        $storageService.setCookie({
          key: 'BTAGCOOKIE',
          data: route.query?.btag,
          expiry: 7,
        });
      }
      if (!!currentBtag && !!route.query?.btag) {
        $storageService.setCookie({
          key: 'REFERRERBTAGCOOKIE',
          data: route.query?.btag,
          expiry: 0,
        });
      }

      $storageService.setUniversalStorage({
        key: 'environment',
        data: runtimeConfig?.public.environment,
      });
    },
    'app:error'(err) {
      console.error({ type: 'app error', err });
    },
    'vue:error'(err, instance, info) {
      console.error({ type: 'vue error', err, instance, info });
    },
    async 'app:mounted'(nuxt) {
      const { $toastService, $gameService } = useServices();
      const siteStore = useSiteStore(nuxt.$nuxt.$pinia);
      const auth = useAuthStore(nuxt.$nuxt.$pinia);

      if (siteStore.getFeatureFlags.get('website.toastmessages')) {
        await $toastService.toastService.fetchToasts();
      }
      await $gameService.fetchBuildVersion({
        regionCode: siteStore.regionCode,
      });

      await auth.initAuth();
    },
  },
});
