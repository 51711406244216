<script lang="ts" setup>
import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';

const { $toastService } = useServices();
const site = useSiteStore();
const auth = useAuthStore();

onUnmounted(() => {
  if (site.getFeatureFlags.get('website.toastmessages')) {
    $toastService.toastService.fetchToasts();
  }
});
</script>
<template>
  <div class="flex items-center user-card">
    <div class="user-card--content rounded-lg mx-auto">
      <div class="user-card--logo">
        <DesignSystemLogo image-class="w-auto" theme="dark" />
      </div>
      <div
        class="user-card--content-copy flex justify-between text-white text-xs"
      >
        <div>
          {{ $t('account-no') }}
          <p class="font-bold text-white">
            {{ auth?.currentUser?.primaryMobileNumber || $t('unavailable') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.user-card {
  margin: 18px 15px 16px;
  padding: 8px 8px 19px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 26px 0 rgba(32, 29, 41, 0.5);
  background-image: linear-gradient(58deg, #2f2b3b 0%, #131217 100%);
  width: 296px;
  height: 186px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  &--content {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    display: flex;
    &-copy {
      p {
        margin-block-start: 0em;
        margin-block-end: 0em;
      }
    }
  }
  &--logo {
    text-align: right;
    & > img {
      height: 40px;
    }
  }
}
</style>
