import { useQueryHooks } from '~/utilities/query-hooks';
import { unlockBodyScroll } from '~/utilities/ui-utilities';
import { useSiteStore } from '~/stores/site.store';

export default defineNuxtPlugin(() => {
  addRouteMiddleware(
    'generic-middleware',
    (to, from) => {
      const siteStore = useSiteStore();
      const errorExclusions = [
        '/',
        '/playground',
        '/promotions',
        '/health',
        '/big-city-life',
        '/favorites',
      ];
      const redirects = [...siteStore.getRedirects];
      const isRedirectAvailable = redirects.find((k) => {
        return k?.fromLink === to.path;
      });

      if (!!isRedirectAvailable) {
        return navigateTo(isRedirectAvailable?.toLink, { redirectCode: 301 });
      } else {
        if (to.path === '/home') navigateTo('/');
        const page = computed(() => {
          if (to.params.contentType === 'home' || to.path === '/')
            return siteStore.getSitemapMap.get(`/`);
          return siteStore.getSitemapMap.get(`/${to.params.contentType}`);
        });
        if (
          typeof page.value === 'undefined' &&
          !errorExclusions.some((e) => to.fullPath.startsWith(e)) &&
          siteStore.getSitemapMap.size !== 0
        ) {
          showError({
            statusCode: 404,
            statusMessage: 'Page Not Found',
            fatal: false,
            unhandled: false,
          });
        }
      }

      nextTick(() => {
        unlockBodyScroll();
        window?.scrollTo({
          top: 0,
        });
      });
    },
    { global: true }
  );
  addRouteMiddleware(
    'query-hook',
    async (to) => {
      const { query } = to;
      const queryKeys = Object.keys(query);
      if (!!query && queryKeys?.length) {
        useQueryHooks(to);
      }
    },
    { global: true }
  );
});
