import { useLocaleStore } from '~/stores/locale.store';

export default defineNuxtPlugin({
  name: 'locale-service',
  parallel: true,
  setup: () => {
    const locale = useLocaleStore();
    window.$t = (k: string) => locale.localeKey(k.toLowerCase());
    return {
      provide: {
        t: (k: string, a?: string) =>
          locale.localeKey(k.toLowerCase(), a?.toLowerCase()),
        locale,
      },
    };
  },
});
