export default defineNuxtPlugin({
  name: 'init-observer',
  parallel: true,
  setup: () => {
    const imageObserver = (entries: Array<IntersectionObserverEntry>) => {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          const lazyImage: HTMLImageElement = <HTMLImageElement>entry.target;
          const fallback: string = lazyImage.src;
          const optimalImage = lazyImage.getAttribute('lazy-src');
          let error = false;
          try {
            const image = new Image();
            image.src = optimalImage;
            image.fetchPriority = 'high';
            image.onerror = (e) => {
              image.onerror = null;
              lazyImage.src = fallback;
              error = true;
              return;
            };
            lazyImage.src = error ? fallback : optimalImage;
            lazyImage.classList.remove('bg-primary-layer');
            observer.unobserve(lazyImage);
          } catch (e) {
            lazyImage.src = fallback;
            observer.unobserve(lazyImage);
            console.error(e);
          }
        }
      });
    };
    const observer: IntersectionObserver = new IntersectionObserver(
      imageObserver,
      {
        threshold: 0.00001,
      }
    );

    function observe(target: HTMLImageElement) {
      if (
        typeof window !== 'undefined' &&
        !!window &&
        'IntersectionObserver' in window &&
        target
      ) {
        observer.observe(target);
      } else target.src = target.getAttribute('lazy-src');
    }
    return {
      provide: {
        imageObserver: observe,
      },
    };
  },
});
