export const accordionTabPassthrough = {
  accordion: {
    root: {
      class: 'bg-base text-base font-bold',
    },
  },
  headerAction: {
    root: {
      class:
        'bg-primary-layer flex gap-2 items-center justify-between p-2 w-full',
    },
  },
  content: {
    root: {
      class: 'bg-base p-2',
    },
  },
  uploadAccordion: {
    root: {
      class: 'bg-base text-base font-bold mb-4 ',
    },
  },
  uploadHeaderAction: {
    root: ({ context }) => ({
      class: [
        'bg-primary-layer flex gap-2 items-center justify-between px-4 py-2 w-full rounded-lg',
        {
          'rounded-b-none border-b border-dark-900': context.active,
        },
      ],
    }),
    toggleicon: {
      class: 'mr-',
    },
  },
  uploadContent: {
    root: {
      class: 'bg-primary-layer rounded-b-lg',
    },
  },
};
