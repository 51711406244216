<script lang="ts" setup>
import type { IGameCategory } from '~/interfaces/dto/games/response';
import CategoryCard from './category-card.vue';
import GenericError from '../user-interface/generic-error.vue';
import { useIntersectionObserver } from '@vueuse/core';
import Button from '../design-system/button.vue';

interface ICategoryGridProps {
  inModal?: boolean;
  games: IGameCategory[];
  loading?: boolean;
  loadingMore?: boolean;
  lazyLoadMore?: boolean;
  maxSize?: number;
}

interface ICategoryGridEmits {
  (e: 'lazy-load', skip: number): void;
}

const gameCard = ref();
const router = useRouter();

const props = withDefaults(defineProps<ICategoryGridProps>(), {
  games: [],
  inModal: false,
  loading: false,
  loadingMore: false,
  lazyLoadMore: false,
  maxSize: 50,
});

const emits = defineEmits<ICategoryGridEmits>();

watch(
  () => gameCard.value,
  (v) => {
    nextTick(() => {
      const lastCard = v[v?.length - 1];

      const { stop } = useIntersectionObserver(
        lastCard,
        async ([{ isIntersecting }]) => {
          if (isIntersecting) {
            if (v?.length >= props?.maxSize) {
              stop();
            }
            emits('lazy-load', v.length);
            stop();
          }
        },
        {
          threshold: 0.001,
        }
      );
    });
  },
  {
    deep: true,
  }
);
</script>
<template>
  <div class="container">
    <div
      v-if="games.length && !loading"
      class="grid pb-5 gap-2"
      :class="
        inModal
          ? 'grid-cols-3 md:grid-cols-4'
          : 'grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 mt-2'
      "
    >
      <CategoryCard
        v-for="(game, index) in games"
        :id="game.id"
        ref="gameCard"
        :key="`${game.name}-${index}`"
        :image="useImagePath(game.tileImage)"
        :title="game.name"
        :subtitle="game.provider"
        :game="game"
        :favorite="game.favorite"
      />

      <div v-if="loadingMore" class="relative">
        <LazyUserInterfaceGenericLoader container />
      </div>
    </div>
    <GenericError v-else state="info">
      <div
        class="flex items-center justify-between text-black dark:text-white text-left w-full"
      >
        <div class="mr-1">
          <div>{{ $t('no-results') }}</div>
          <div>{{ $t('no-results-message') }}</div>
        </div>
        <Button @click="router.push('/')">
          {{ $t('reset-filters') }}
        </Button>
      </div>
    </GenericError>
  </div>
</template>
