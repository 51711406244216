import type { IMarketingSessionAddRequest } from '~/interfaces/dto/marketing/request';
import { useStorageService } from '~/composables/services/useStorageService';
import { useMarketingService } from '~/composables/services/useMarketingService';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useLocaleStore } from '~/stores/locale.store';
import { useAddMarketingSession } from '~/composables/useAddMarketingSession';

export default defineNuxtPlugin({
  name: 'marketing-optin-service',
  enforce: 'post',
  parallel: true,
  dependsOn: ['init-client'],
  hooks: {
    async 'app:mounted'() {
      const auth = useAuthStore();
      const site = useSiteStore();
      const locale = useLocaleStore();
      const route = useRoute();
      const { query } = route;

      const $storageService = useStorageService();
      const $marketingService = useMarketingService();

      const requestInfo = await $marketingService.fetchDeviceInfo();

      $storageService.setUniversalStorage({
        key: 'device-info',
        data: requestInfo,
      });

      if (auth.loggedIn && !!query?.optin) {
        if (!!query?.optin) {
          await $marketingService
            .setMarketingOptIn({
              vendorAccountID: $storageService.getLocalStorage({
                key: 'auth:userId',
              }),
              optinName: String(query?.optin),
              source: String(query?.source),
              countryCode: site.getRegionCode,
            })
            .then((d) => {
              if (typeof d === 'boolean' && d) {
                site.toggleSiteNotice({
                  heading: locale.localeKey('marketing-opt-in-heading'),
                  message: locale.localeKey('marketing-opt-in-message'),
                  severity: 'success',
                  enabled: true,
                  timeout: 5000,
                });
              }
            });
        }
      } else if (!auth.loggedIn && !!query?.optin) {
        $storageService.setLocalStorage({
          key: 'optinSession',
          data: JSON.stringify({
            optin: query.optin,
            source: query.source,
          }),
        });

        watch(
          () => auth.loggedIn,
          async (value) => {
            if (value) {
              const optin = $storageService.getLocalStorage({
                key: 'optinSession',
              });
              await $marketingService
                .setMarketingOptIn({
                  vendorAccountID: $storageService.getLocalStorage({
                    key: 'auth:userId',
                  }),
                  optinName: String(optin?.optin),
                  source: String(optin?.source),
                  countryCode: site.getRegionCode,
                })
                .then((d) => {
                  if (typeof d === 'boolean' && d) {
                    site.toggleSiteNotice({
                      heading: locale.localeKey('marketing-opt-in-heading'),
                      message: locale.localeKey('marketing-opt-in-message'),
                      severity: 'success',
                      enabled: true,
                      timeout: 5000,
                    });
                    $storageService.removeLocalStorage({
                      key: 'optinSession',
                    });
                  }
                });
            }
          }
        );
      }

      //Create marketing session if none exists
      if (auth.isLoggedIn) useAddMarketingSession();

      // lexis nexis threat metrix

      if (!window?.thkdlhgd && site.getRegionCode === 'JZA') {
        (function (d, src) {
          const script = d.createElement('script');
          script.type = 'text/javascript';
          script.defer = true;
          script.async = true;
          script.onload = function () {
            if (!!window?.ProfileInit) window?.ProfileInit();
          };
          script.src = src;
          d.getElementsByTagName('body')[0].appendChild(script);
        })(document, 'https://cdn.jpc.africa/BWA/lntk.js');
      } else {
        if (!!window?.ProfileInit) window?.ProfileInit();
      }
    },
  },
});
